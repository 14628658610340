<template lang="html">
 <div id="chat-app" class="border border-solid d-theme-border-grey-light rounded relative overflow-hidden">
        <vs-sidebar style="direction:ltr" class="items-no-padding" parent="#chat-app" :click-not-close="clickNotClose" :hidden-background="clickNotClose" v-model="isChatSidebarActive" id="chat-list-sidebar">

            <!-- tikcket Subject SIDEBAR -->

            <vs-divider v-once  class="d-theme-border-grey-light m-0" />

            <component :is="scrollbarTag" class="chat-scroll-area pt-4" :settings="settings" :key="$vs.ltr">


                <div class=" mb-8">
                  <!-- دکمه ساخت تیکت -->
                  <create-tiket @refreshList="getTiket()" ></create-tiket>
                   <!--  -->
                    <vs-divider></vs-divider>
                    <ul  class="chat__active-chats bordered-items">
                        <li class="cursor-pointer" v-for="(item, index) in tiketList" :key="index"  @click="updateActiveChatUser(item)" >
                            <tiket-list  showLastMsg :tiketList="item" :chatData="chatData" :isActiveChatUser="isActiveChatUser(item.id)" ></tiket-list>
                        </li>
                    </ul>
                </div>

            </component>
        </vs-sidebar>


        <div dir="rtl" class="chat__bg no-scroll-content chat-content-area border border-solid d-theme-border-grey-light border-t-0 border-r-0 border-b-0" :class="{'sidebar-spacer--wide': clickNotClose, 'flex items-center justify-center': activeChatUser === null}">
            <template v-if="activeChatUser">
             <div class="chat__navbar ">
                    <tiket-navbar v-if="windowWidth < 800" :tiketDetail="navbarDetails" class="p-3" :userId="this.activeChatUser" @openContactsSidebar="toggleChatSidebar(true)" @showProfileSidebar="showProfileSidebar"   @toggleIsChatPinned="toggleIsChatPinned"></tiket-navbar>
                </div>
              <!-- clolllapse nav bar -->

                <component style="direction:ltr" :is="scrollbarTag"   id="chat-list-sidebar" class="chat-content-scroll-area border border-solid d-theme-border-grey-light" :settings="settings" ref="chatLogPS" :key="$vs.rtl">
                    <div  class="chat__log" ref="chatLog">
                        <tiket-log :detail="navbarDetails" :chatData="chatData" v-if="activeChatUser"></tiket-log>
                    </div>
                </component>
                <div class="chat__input flex margin-fix  p-4 bg-white" :class="windowWidth > 800 ? 'mt-20' : ''">
                    <vs-button :disabled="loading" loading="true" class="bg-primary-gradient rounded-pill text-center align-center  mr-4 round"  type="filled" @click="sendMsg">
                      <b-spinner v-if="loading" small ></b-spinner>
                      <span v-else>ارسال </span>                     </vs-button>
                    <vs-input class="flex-1 " placeholder="پیام ... " v-model="typedMessage" @keyup.enter="sendMsg" />
                </div>
            </template>
            <template v-else>
                <div class="flex flex-col items-center">
                    <feather-icon icon="MessageSquareIcon" class="mb-4 bg-white p-8 shadow-md rounded-full" svgClasses="w-16 h-16"></feather-icon>
                    <h4 class=" py-2 px-4 bg-white shadow-md rounded-full cursor-pointer" @click.stop="toggleChatSidebar(true)"> شروع گفتگو</h4>
                </div>
            </template>
        </div>
    </div>
</template>

<script>

import TiketList            from   './TiketList.vue'
import VuePerfectScrollbar  from 'vue-perfect-scrollbar'
import TiketLog             from './TiketLog.vue'
import CreateTiket          from  './CreateTiket.vue'
import TiketNavbar          from   './TiketNavbar.vue'

export default {
  data () {
    return {
      loading              : false,
      active               : true,
      isHidden             : false,
      searchContact        : '',
      activeProfileSidebar : false,
      activeChatUser       : null,
      userProfileId        : -1,
      typedMessage         : '',
      isChatPinned         : false,
      settings             : {
        maxScrollbarLength : 60,
        wheelSpeed         : 0.70

      },
      clickNotClose        : true,
      isChatSidebarActive  : true,
      isLoggedInUserProfileView: false,
      tiketList             : [],
      chatData              :{},
      navbarDetails         : {}

    }
  },
  watch: {
    windowWidth () {
      this.setSidebarWidth()
    },
    activeChatUser () {
      this.getAllComment()
    },
    $route (value) {
      if (value.path === '/apps/ticket') {

        this.getTiket()
      }
    }
  },
  computed: {
    // chatLastMessaged () {
    //   // return (userId) => this.$store.getters['chat/chatLastMessaged'](userId)
    // },
    // chatUnseenMessages () {
    //   return (userId) => {
    //     // const unseenMsg = this.$store.getters['chat/chatUnseenMessages'](userId)
    //     if (unseenMsg) return unseenMsg
    //   }
    // },
    activeUser () {
      return this.$store.state.AppActiveUser
    },
    getStatusColor () {
      return (isActiveUser) => {
        const userStatus = this.getUserStatus(isActiveUser)

        if (userStatus === 'online') {
          return 'success'
        } else if (userStatus === 'do not disturb') {
          return 'danger'
        } else if (userStatus === 'away') {
          return 'warning'
        } else {
          return 'grey'
        }
      }
    },
    // chatContacts () {
    //   return this.$store.getters['chat/chatContacts']
    // },
    // contacts () {
    //   return this.$store.getters['chat/contacts']
    // },

    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    },
    isActiveChatUser () {
      return (userId) => userId === this.activeChatUser
    },
    windowWidth () {
      return this.$store.state.windowWidth
    }
  },
  methods: {
    openContactsSidebar () {

    },

    getTiket () {
      this.$http.get('/ticket').then(res => {
        const data = res.data.params.data
        const parsedobj = JSON.stringify(data)
        this.tiketList = JSON.parse(parsedobj).reverse()

      }).catch(err => {
        this.$vs.notify({
          color:'',
          Text:err.response.data.message,
          title:'تیکت'
        })
      })
    },
    getUserStatus (isActiveUser) {
      // return "active"
      return isActiveUser ? this.$store.state.AppActiveUser.status : this.contacts[this.activeChatUser].status
    },
    closeProfileSidebar (value) {
      this.activeProfileSidebar = value
    },
    getAllComment () {
      this.$http.get(`ticket/${ this.activeChatUser}`).then(res => {
        const  data = res.data.params.data
        const parsedobj = JSON.stringify(data)
        const p = JSON.parse(parsedobj)

        // eslint-disable-next-line prefer-const
        this.chatData = p

      })
    },
    async updateActiveChatUser (tiket) {
      this.navbarDetails = await tiket

      this.activeChatUser = await tiket.id
      this.getAllComment()

      this.toggleChatSidebar()
      this.typedMessage = ''
    },
    showProfileSidebar (userId, openOnLeft = false) {
      this.userProfileId = userId
      this.isLoggedInUserProfileView = openOnLeft
      this.activeProfileSidebar = !this.activeProfileSidebar
    },
    sendMsg () {
      this.loading = true
      this.$http.post(`/ticket/comment/${this.activeChatUser}`, { body : this.typedMessage}).then(res => {
        this.loading = false
        this.$vs.notify({
          color:'success',
          title:'تیکت ',
          text:res.data.message
        })
        this.getAllComment(this.activeChatUser)
      })
      this.typedMessage = ''

      const scroll_el = this.$refs.chatLogPS.$el || this.$refs.chatLogPS
      scroll_el.scrollTop = this.$refs.chatLog.scrollHeight
    },
    toggleIsChatPinned (value) {
      this.isChatPinned = value
    },

    setSidebarWidth () {
      if (this.windowWidth < 800) {
        this.isChatSidebarActive = this.clickNotClose = false
      } else {
        this.isChatSidebarActive = this.clickNotClose = true
      }
    },
    toggleChatSidebar (value = false) {
      if (!value && this.clickNotClose) return
      this.isChatSidebarActive = value
    }
  },
  components: {
    VuePerfectScrollbar,
    TiketList,
    TiketLog,
    TiketNavbar,
    CreateTiket
  },
  created () {
    this.setSidebarWidth()
    this.getTiket()
  }
}

</script>


<style lang="scss">
@import "@/assets/scss/vuexy/apps/chat.scss";
.position {
  position: absolute;
  width: 100%;
}

</style>
