<template>
    <div>
        <div class="m-6 " dir="rtl">
            <b-button class="w-full bg-h-primary  px-12 py-3"  icon-pack="feather" icon="icon-edit" @click="activePrompt = true">
              <b-icon class="mx-2" icon="pencil-square"></b-icon>
              ساخت تیکت
              </b-button>
        </div>

        <!-- compose email -->
        <vs-prompt
            class="email-compose"
            color="#cf2f60"
            title="ارسال تیکت"
            accept-text= "ایجاد تیکت"
            cancel-text="انصراف"
            @cancel="clearFields"
            @accept="createTiket"
            @close="clearFields"
            :active.sync="activePrompt">
                <component :is="scrollbarTag" class="scroll-area p-3" style="direction: ltr" :settings="settings" :key="$vs.ltr">
                    <form @submit.prevent>
                        <vs-input  label-placeholder="موضوع" style="direction: rtl" v-model="subject" class="w-full mb-6" />
                            <v-select class="mb-6" v-model="selectPart" :value="productOptions.value" label="label" :options="productOptions"></v-select>
                            <v-select class="mb-6" v-model="selectProduct" :value="unitOptions.value" label="text" :options="unitOptions"></v-select>
                            <b-textarea style="direction:rtl" v-model="message" label=" پیام خود را وارد کنید" height="80px" />
                        <!-- <quill-editor  v-model="message" :options="editorOption" /> -->
                        <div class="wrapper">
                          <div class="file-upload">

                        <input  type="file"  text="Attachments" ref="fileUpload" :show-upload-button="false" class=" mt-0 text-h-secondary compose-mail-attachment" />
                         <i class="Mail"></i>
                        </div>
                        </div>

                    </form>
                </component>
        </vs-prompt>
    </div>
</template>

<script>

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import { quillEditor } from 'vue-quill-editor'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  emits: ['refreshList'],
  components:{
    quillEditor,
    VuePerfectScrollbar
  },
  data () {
    return {
      /********************************************************************************/

      /***************************************************************************/
      subject : '',
      selectPart : '',
      selectProduct:'',
      productOptions:[
        {label:'بخش پشتیبانی', value:'1'},
        {label:'بخش فروش', value:'2'}

      ],
      unitOptions:[
        {text:'سرویس ایمیل', value:'1'},
        {text:'سرویس های ابری', value:'2'},
        {text:'سرویس مانیتورینگ', value:'3'}

      ],
      message : '',

      activePrompt: false,
      settings             : {
        maxScrollbarLength : 60,
        wheelSpeed         : 0.30
      },
      ticketData : {}
    }

  },

  computed :{
    scrollbarTag () { return this.$store.getters.scrollbarTag }

  },
  methods:{
    clearFields () {
      this.subject = ''
      this.message = ''
    },
    createTiket () {
      const ticketData = {
        subject : this.subject,
        body : this.message,
        label_id :this.selectPart.value,
        category_id : this.selectProduct.value,
        attachment : this.$refs.fileUpload.files[0]
      }
      let form_data = null
      if (ticketData.attachment) {
        form_data = new FormData()

        for (const key in ticketData) {
          form_data.append(key, ticketData[key])
        }

      } else {
        form_data = new FormData()

        for (const key in ticketData) {
          form_data.append(key, ticketData[key])
        }
        form_data.delete('attachment')


      }

      // console.log(form_data)
      this.$http.post('/ticket', form_data, {headers: {'Content-Type': 'multipart/form-data' }}).then(res => {
        this.$vs.notify({
          color:'success',
          title:'تیکت ',
          text:res.data.message
        })
        this.$emit('refreshList')

      }).catch(err => this.$vs.notify({
        color:'danger',
        title:'تیکت ',
        text:err.response.data.message
      }))
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/vuexy/apps/email.scss";

// .wrapper{

//   width:100%;
//   height:100%;
//   display:flex;
//   align-items:center;
//   justify-content:center;


//   .file-upload{

//     height:200px;
//     width:200px;
//     border-radius: 100px;
//     position:relative;

//     display:flex;
//     justify-content:center;
//     align-items: center;

//     border:4px solid #ffffff;
//     overflow:hidden;
//     background-image: linear-gradient(to bottom, #2590EB 50%, #ffffff 50%);
//     background-size: 100% 200%;
//     transition: all 1s;
//     color: #000000;
//     font-size:100px;

//     input[type='file']{

//       height:200px;
//       width:200px;
//       position:absolute;
//       top:0;
//       left:0;
//       opacity:0;
//       cursor:pointer;

//     }

//     &:hover{

//       background-position: 0 -100%;

//       color:#2590EB;

//     }


//   }
// }
</style>
