<template>
    <div  id="component-chat-log" class="m-8" v-if="chatData">
      <div  class="chat-content-scroll-area flex items-end flex flex-col mb-base bg-white p-4 rounded-lg" >
            <vs-chip dir="rtl">
               موضوع
              :
              {{detail.subject}}
            </vs-chip>

            <vs-chip>تاریخ ایجاد : {{toDate(detail.created_at)}}  </vs-chip>
<!--            <vs-chip v-show="chatData.agent_fullname">-->

<!--              نام کارشناس پاسخ دهنده-->
<!--              :-->
<!--              {{chatData.agent_fullname}}-->

<!--            </vs-chip>-->

            <vs-chip color="warning" v-if="chatData.ticket_side == false">  تیکت ارسالی از طرف پشتیبانی هایو</vs-chip>
            <vs-divider></vs-divider>

                <div>

                <div dir="rtl" class="msg break-words relative shadow-md rounded py-3 px-3 mb-1 rounded-lg max-w-sm" :class="{'bg-primary-gradient text-white': chatData.ticket_side == true , 'border border-solid border-transparent bg-white': chatData.ticket_side==false}">
                    <span>{{ chatData.body }}</span>

                </div>
                </div>
            </div>

        <div v-for="(msg, index) in chatData.comments" class="msg-grp-container" :key="index">

            <!-- If previous msg is older than current time -->
            <template v-if="chatData.comments[index-1]">
                <vs-divider v-if="!isSameDay(msg.created_at, chatData.comments[index-1].created_at)" class="msg-time">

                    <span>{{ toDate(msg.created_at, 'long') }}</span>
                </vs-divider>
                <div class="spacer mt-8" v-if="!hasSentPreviousMsg(chatData.comments[index-1].comment_side, msg.comment_side)"></div>
            </template>

            <div  class="flex items-start" :class="[{'flex-row-reverse' : msg.comment_side=='1'}]">

                <template v-if="chatData.comments[index-1]">
                    <template v-if="(!hasSentPreviousMsg(chatData.comments[index-1].comment_side, msg.comment_side=='1') || !isSameDay(msg.created_at, chatData.comments[index-1].created_at))">
                        <vs-avatar></vs-avatar>
                    </template>
                </template>

                <template v-if="index == 0">
                    <vs-avatar></vs-avatar>
                </template>

                <template  dir="rtl" v-if="chatData.comments[index-1]">
                    <div class="mr-16" v-if="!(!hasSentPreviousMsg(chatData.comments[index-1].comment_side, msg.comment_side=='1') || !isSameDay(msg.created_at, chatData.comments[index-1].created_at))"></div>
                </template>


                <div dir="rtl" class="msg break-words relative flex flex-column shadow-md rounded py-3 px-3 mb-1 rounded-lg max-w-sm" :class="{'bg-primary-gradient text-white': msg.comment_side == true , 'border border-solid border-transparent bg-white': msg.comment_side==false}">
                    <span v-html="msg.body"></span>
                  <vs-chip class="mt-1 mx-10 flex items-center" color="" >{{ msg.created_at }} <p class="text-center m-1">{{  msg.user_fullname !== equlaName ? msg.user_fullname : ''}}</p></vs-chip>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  props:['chatData', 'detail'],
  data () {
    return {
      equlaName: ''
    }

  },
  computed: {
    exttractBody (h) {
      return HTMLElement.children(h)
    },
    hasSentPreviousMsg () {
      return (last_sender, current_sender) => last_sender === current_sender
    }
  },
  methods: {
    handleShowNameResponser () {
      this.equlaName = JSON.parse(localStorage.getItem('userInfo')).name
    },
    isSameDay (time_to, time_from) {
      const date_time_to = new Date(Date.parse(time_to))
      const date_time_from = new Date(Date.parse(time_from))
      return date_time_to.getDate() === date_time_from.getDate()
    },
    toDate (time, option) {
      const locale = 'fa-ir'
      const date_obj = new Date(Date.parse(time))
      const monthName = date_obj.toLocaleString(locale, {
        month : option
      })
      return ` ${   monthName}`
    },
    scrollToBottom () {
      this.$nextTick(() => {
        this.$parent.$el.scrollTop = this.$parent.$el.scrollHeight
      })
    }
  },
  updated () {
    this.scrollToBottom()
  },
  mounted () {
    this.scrollToBottom()
    this.handleShowNameResponser()
  }


}
</script>
