<template>
    <div dir="rtl" class="chat__contact  items-center px-2 pt-4 pb-2" :class="{'bg-primary-gradient text-white shadow-lg': isActiveChatUser}">
         <div class="contact__avatar float-right bg-h-secondary px-3 py-1 text-white rounded-pill ">
          <div class="text-center text-xs">{{tiketList.status_id === 3 ? 'در انتظار پاسخ':tiketList.status_id === 4 ? ' پاسخ داده شده' :tiketList.status_id === 2 ? '' : 'جدید'}}</div>
        </div>

        <div class="contact__container m-2 w-full flex items-center justify-between ">
            <div dir="rtl" class="contact__info flex flex-col truncate w-5/6">
                <h5  dir="rtl" class="font-semibold mb-2  text-black"> {{ tiketList.subject }}</h5>
                 <span class="truncate">{{ tiketList.body }} </span>
            </div>
              <div class="ml-1 h-3 w-3 rounded-full mr-4" :class="{'border-2 border-solid border-warning': tiketList.ticket_side===false}"></div>
            <div class="chat__contact__meta flex self-start flex px-2 items-end w-1/6">
                <span class="whitespace-no-wrap"></span>
                <vs-chip> {{tiketList.id}}</vs-chip>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  props: ['tiketList', 'isActiveChatUser', 'chatData'],

  methods: {
    toDate (time) {
      const locale = 'fa-ir'
      const date_obj = new Date(Date.parse(time))
      const monthName = date_obj.toLocaleString(locale, {
        month: 'short'
      })
      return `${date_obj.getDate()  } ${   monthName}`

    }
  },
  computed : {
    activeUserInfo () {
      return this.$store.state.AppActiveUser
    }
  },
  created () {
    // console.log(this.chatData)
  }
}
</script>
