<template>
    <div  v-if="userId != null" class="chat__header" >
        <vs-navbar class="p-4 flex navbar-custom" color="white" type="flat">
            <div class="relative flex items-center mr-4"  @click.stop="$emit('openContactsSidebar')">
                <b-icon icon="arrow-right" class="mr-4 cursor-pointer"   />
                <span>لیست تیکت ها</span>
            </div>

            <vs-spacer></vs-spacer>
        </vs-navbar>
    </div>
</template>

<script>
export default {
  props:['userId', 'tiketDetail'],
  emits:['openContactsSidebar', 'showProfileSidebar'],
  data:() => ({

    activeItem: 0

  }),
  computed : {
    windowWidth () {
      return this.$store.state.windowWidth
    }
  }
}
</script>
